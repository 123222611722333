import discord from '../assets/icons/discord.svg';
import instagram from '../assets/icons/instagram.svg';
import reddit from '../assets/icons/reddit.svg';
import x_twitter from '../assets/icons/icon-x.png';


const Footer = ()=> {
    return (
        <footer className="footer">
            <p>Copyright © 2024 Bcoal | All rights reserved.</p>
            <div className="social_icons">
                <a href="https://discord.gg/bcoal">
                    <img src={discord}/>
                </a> 
                <a href="https://www.instagram.com/BCOAL_">
                    <img src={instagram}/>
                </a> 
                <a href="https://www.reddit.com/r/BCoalXLM">
                    <img src={reddit}/>
                </a> 
                <a href="https://twitter.com/bcoalxlm">
                    <img src={x_twitter}/>
                </a> 
            </div>
        </footer> 
    )
}
export default Footer;