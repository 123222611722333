import React, { useState, useEffect, useRef } from 'react'; 
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';  
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/*---------| Media |---------*/
import icon_btn from '../assets/images/icon-button.png';
import realiases from '../assets/images/regalias2.png';
import rocks from '../assets/images/rocks.png';
import moneda from '../assets/images/moneda.png';
import banner_man from '../assets/images/team_images/banner-man.webp';
import multi_phone from '../assets/images/multi-phone.webp';
import close from '../assets/icons/close.webp';
import left_border from '../assets/icons/trazado2.webp';
import right_border from '../assets/icons/trazado1.webp';
import rotating_globe from '../assets/images/gif/rotating-glob-darker.gif';
import team1 from '../assets/images/team_images/team1.png';
import team2 from '../assets/images/team_images/team2.png';
import team3 from '../assets/images/team_images/team3.png';
import team4 from '../assets/images/team_images/team4.png';
import team5 from '../assets/images/team_images/team5.png';
import team6 from '../assets/images/team_images/team6.png';
import team7 from '../assets/images/team_images/team7.png';
import team8 from '../assets/images/team_images/team8.png';

const Home = ()=> {
    const [activeHeroTab, setActiveHeroTab] = useState('discover');
    const [menuClass, setMenuClass] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [slidesPerView, setSlidesPerView] = useState(isMobile ? 1 : 4);
    const [spaceBetween, setSpaceBetween] = useState(isMobile ? 6 : 70);
    function getDate() { 
        const currentDate = new Date(); 
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;  
        const year = currentDate.getFullYear();  
        return `${month}/${day}/${year}`;
    }    
    
    const titleRef = useRef(null); 
    const chart1 = useRef(null);
    const chart2 = useRef(null); 
    const man_paragraph = useRef(null); 
    const chartContentRef = useRef(null);
    useEffect(() => {
        gsap.from(titleRef.current, {
            x: -300,  
            opacity: 0, 
            duration: 3,  
            ease: 'power4.out', 
        }); 

        gsap.registerPlugin(ScrollTrigger);  
        gsap.from([chart1.current, chart2.current], {
            opacity: 0,
            duration: 1,
            scrollTrigger: {
                trigger: chart1.current, // Use one of the charts as the trigger
                start: 'top 80%',
                toggleActions: 'play none none none' // When to start the animation
            }
        });
        
        gsap.from('.count_bar', {
            width: 0, 
            duration: 5,
            ease: 'power4.out',
            scrollTrigger: {
                trigger: chartContentRef.current,
                start: 'top 80%',
                toggleActions: 'play none none none' // When to start the animation
            }
        });
        
        gsap.from('.moneda', {
            x: -400, 
            rotate: -360,
            duration: 5,
            ease: 'power4.out',
            scrollTrigger: {
                trigger: chartContentRef.current,
                start: 'top 80%',
                toggleActions: 'play none none none' // When to start the animation
            }
        });

        gsap.from([man_paragraph.current], {
            opacity: 0,
            duration: 1,
            scrollTrigger: {
                trigger: man_paragraph.current, // Use one of the charts as the trigger
                start: 'top 60%',
                toggleActions: 'play none none none' // When to start the animation
            }
        });
        
        const features = document.querySelectorAll('.bcoal_feature h3 span');
        features.forEach((number) => {
            const targetValue = parseInt(number.textContent, 10);
            let counter = 0;
            const duration = 2000; // Duration of the animation in milliseconds
            const step = Math.ceil((targetValue / duration) * 10); // Calculate the step value based on the target value and duration

            ScrollTrigger.create({
                trigger: number, // Use the number element as the trigger
                start: 'top 80%',
                onEnter: () => {
                    const animateCounter = () => {
                        const intervalId = setInterval(() => {
                            counter += step;
                            if (counter >= targetValue) {
                                clearInterval(intervalId);
                                counter = targetValue;
                            }
                            number.textContent = counter;
                        }, 40); // Update every 10 milliseconds
                    };

                    animateCounter();
                },
                once: true // Animation will only trigger once
            });
        });

        const normal_fade_in = document.querySelectorAll('.will_fade_in'); 
        normal_fade_in.forEach((paragraph, index) => {
            gsap.from(paragraph, {
                opacity: 0,
                y: 50,
                duration: 1,
                scrollTrigger: {
                    trigger: paragraph,
                    start: 'top 80%',
                    toggleActions: 'play none none none',
                    delay: index * 0.5 // Delay each paragraph animation by 0.5 seconds
                }
            });
        });

        gsap.from('.technology .img_side', {
            x: -300,  
            opacity: 0, 
            duration: 3,  
            ease: 'power4.out', 
            scrollTrigger: {
                trigger: '.technology .img_side', // Use one of the charts as the trigger
                start: 'top 60%',
                toggleActions: 'play none none none' // When to start the animation
            }
        }); 
    }, []);


    useEffect(() => {
        function handleResize() {
            const mobile = window.innerWidth < 768;
            setIsMobile(mobile);

            if (mobile) {
                setSlidesPerView(1);
                setSpaceBetween(6);
            } else {
                setSlidesPerView(4);
                setSpaceBetween(120);
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className="home"> 
            <div className="hero">
                <div className="hero_container">
                    <h1 ref={titleRef}>It has always been among us</h1> 
                    <div className="hero_tabs">
                        <div className="tab_buttons_container">
                            <div className="tab_button" onClick={()=> { setActiveHeroTab('discover') }}>
                                Discover their involvement in history. <img src={icon_btn} />
                            </div>
                            <div className="tab_button" onClick={()=> { setActiveHeroTab('means') }}>
                                The means of BCOAL <img src={icon_btn} />
                            </div>
                        </div>
                        <div className="tabs_content_container">
                            {activeHeroTab == 'discover' && (
                                <div className='content_container'>
                                    <p>
                                        Coal, in its simplest form, has been a fundamental part of history. Since the dawn of civilization, it has been the fuel that ignited life, providing light, power, and protection; it fueled machines, dreams, and revolutions that changed the way we understand the world. But its value doesn't stop there. It has been shaped and transformed over the centuries, becoming more than just a small rock. It is the engine of progress, the spark of innovation, and the heart of the industrial revolution.
                                    </p> 
                                    <p>Currently, it is one of the minerals that can be found naturally on 0.02% of the Earth's surface.</p>
                                    <img src={rotating_globe} alt="" />
                                </div>
                            )}
                            {activeHeroTab == 'means' && (
                                <p>
                                    BCOAL is an innovative project by which a cryptocurrency backed by a real-world asset of tangible coal reserves can merge with the stability of traditional commodities with the efficiency of blockchain technology.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div id="whatisbcoal"></div>
            <div className="true_value" >
                <h2>Its true value</h2>
                <p>The token’s value is firmly supported by an equivalent amount of coal reserves establishing a dependable and transparent investment.</p>
                <p>BCOAL represents a direct connection between digital value and real-world coal resources. Each BCOAL token symbolizes one ton of Illinois Basin Bituminous Coal, reflecting a tangible asset in the digital realm.</p>
                <div className="true_value_container">
                    <div className="left_side">
                        <div className="chart_card_container" ref={chart1}>
                            <h3 className="chart_title">Coal royalties</h3>
                            <img src={realiases} />
                        </div>
                        <div className="chart_card_container" ref={chart2}>
                            <h3 className="chart_title">World coal production for industry</h3> 
                            <div className="horisontal_chart_content" ref={chartContentRef}>
                                <div>
                                    <div></div>
                                    <div></div>
                                    <div className='small_size_text'>Mill Ton</div>
                                </div>
                                <div>
                                    <div>Sep</div>
                                    <div>
                                        <div className='count_bar' style={{ width: '96.67%' }}></div>
                                    </div>
                                    <div>8.7</div>
                                </div>
                                <div>
                                    <div>Aug</div>
                                    <div>
                                        <div className='count_bar' style={{ width: '100%' }}> </div>
                                    </div>
                                    <div>9</div>
                                </div>
                                <div>
                                    <div>Jul</div>
                                    <div>
                                        <div className='count_bar' style={{ width: '78.89%' }}> </div>
                                    </div>
                                    <div>7.1</div>
                                </div>
                                <div>
                                    <div>Jun</div>
                                    <div>
                                        <div className='count_bar' style={{ width: '88.89%' }}> </div>
                                    </div>
                                    <div>8</div>
                                </div>
                                <div>
                                    <div>May</div>
                                    <div>
                                        <div className='count_bar' style={{ width: '96.67%' }}> </div>
                                    </div>
                                    <div>8.7</div>
                                </div>
                                <div>
                                    <div>Apr</div>
                                    <div>
                                        <div className='count_bar' style={{ width: '82.22%' }}> </div>
                                    </div>
                                    <div>7.4</div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className="middle_side">
                        <div className="us_dollar">
                            <small className="current">Current</small>
                            <span>us$ 44.75</span>
                            <span className='up_arr'>▲</span>
                            <small>Date: {getDate()}</small>
                        </div>
                        <img src={rocks} className='middle_rocks'/>
                    </div>
                    <div className="right_side"> 
                        <img src={moneda} className='moneda' />
                    </div>
                </div>
            </div>
            <div id="ourteam"></div>
            <div className="our_team">
                <h2>Our Team </h2>
                <div className="team_slider">
                    <Swiper
                        modules={[Navigation]}
                        navigation
                        spaceBetween={spaceBetween}
                        slidesPerView={slidesPerView}
                    >
                        <SwiperSlide>
                            <img src={team1} />
                            <div className="team_name">Bryan Goldstein</div>
                            <div className="team_role">Founder & CEO</div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={team2} />
                            <div className="team_name">Ray Tutela</div>
                            <div className="team_role">President of Operations</div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <img src={team3} />
                            <div className="team_name">Anthony Menza</div>
                            <div className="team_role">Community Relations Director</div>
                        </SwiperSlide>  */}
                        <SwiperSlide>
                            <img src={team5} />
                            <div className="team_name">John Sisk</div>
                            <div className="team_role">Civil Engineer & Land Surveyor</div>
                        </SwiperSlide> 
                        <SwiperSlide>
                            <img src={team7} />
                            <div className="team_name">Taylor Hills</div>
                            <div className="team_role">Project Manager</div>
                        </SwiperSlide> 
                        <SwiperSlide>
                            <img src={team8} />
                            <div className="team_name">Kevin O’Leary</div>
                            <div className="team_role">Blockchain Project Specialist</div>
                        </SwiperSlide> 
                        <SwiperSlide>
                            <img src={team6} />
                            <div className="team_name">Jessica Walker</div>
                            <div className="team_role">Web Developer </div>
                        </SwiperSlide> 
                    </Swiper> 
                </div>
            </div>
            <div className="evolution">
                <img src={banner_man} className='banner_man' />
                <p ref={man_paragraph}>BCOAL leverages the Stellar network for its operations, guaranteeing swift, cost- effective transactions and facilitating worldwide accessibility</p>
            </div>
            <div className="what_is_bcoal">
                <div className="bcoal_container">
                    <h2>BCOAL is</h2>
                    <div className="bcoal_features_container">
                        <div className="bcoal_feature">
                            <h3><span>23</span></h3>
                            <p>Million tons of Coal</p>
                        </div>
                        <div className="bcoal_feature">
                            <h3><span>50</span><small>k</small></h3>
                            <p>initial token supply</p>
                        </div>
                        <div className="bcoal_feature">
                            <h3><small>USD$</small><span>35</span><small>.00</small></h3>
                            <p>per token</p>
                        </div>
                        <div className="bcoal_feature">
                            <h3><span>75</span></h3>
                            <p>countries reached</p>
                        </div>
                    </div>
                    <p className='will_fade_in'><span>BCOAL</span> reserves consist of impressive 23,000,000 tons of above ground Illinois basin call located in Fiatt, Illinois. The Initial Coin Offering (ICO) of BCOAL will launch with an initial supply of 50,000 tokens at a discounted price per token for $35. The current market price per token of BCOAL is $52. It also has the potential of additional appreciation as more coal is tokenized</p> 
                </div>
            </div>
            <div id="aboutus"></div>
            <div className="about_technology">
                <div className="about_us">
                    <h2>About us</h2>
                    <div className='tech_paragraph'>
                        <p className='will_fade_in'>BCOAL introduces an innovative concept: a cryptocurrency backed by tangible coal reserves.</p>
                        <p className='will_fade_in'>BCOAL merges the stability of traditional commodities with the efficiency of blockchain technology.</p>
                        <p className='will_fade_in'>As a Stellar-based token, each BCOAL token represents one ton of Illinois Basin Bituminous Coal, creating a direct link between digital value and a tangible resource. This tokenization introduces a paradigm shift, where a real-world asset becomes an immutable digital representation on the blockchain.</p>
                        <p className='will_fade_in'>The Initial Coin Offering (ICO ) of BCOAL just launched with an initial supply of 50,000 tokens, at a discounted price of $35 for early investors. Each token purchased is backed by 1 ton of Illinois basin coal with a current market value of $43. Thus presenting the potential of the appreciation as more coal reserves are tokenized..</p>
                        <p className='will_fade_in'>The BCOAL ecosystem outlines its legal compliance measures and highlights its strategic marketing approach.</p>
                        <p className='will_fade_in'>By participating in the ICO, investors can be at the forefront of this groundbreaking project and contribute to a more stable feature of the cryptocurrency landscape.</p>
                        <p className='will_fade_in'>
                        BCOAL LLC
                            <br/>
                            2980 NE 207th st Ste #300
                            <br />
                            Aventura, FL. 33180
                            <br/>
                            <span>Phone Number</span> <a href="tel:1+(855) 226-2546">1+(855) 226-2546</a> 
                            <br/>
                            <span>Email</span> <a href="mailto:info@bcoal.io">info@bcoal.io</a>
                        </p>
                        <p className='will_fade_in'>
                            <span>Investor Relations:</span>  <a href="mailto:Investors@bcoal.io">Investors@bcoal.io</a>
                            <br/>
                            <span>Help/Support</span>  <a href="mailto:Support@bcoal.io.">Support@bcoal.io. </a>
                            <br/>
                            <span>Marketing Relations:</span>  <a href="mailto:Marketing@Bcoal.io">Marketing@Bcoal.io</a>
                        </p>
                    </div>
                </div>
                <div className="technology">
                    <div className="img_side">
                        <img src={multi_phone} />
                    </div>
                    <div className="technology_text">
                        <h2 className='will_fade_in'>Technology is the way</h2>
                        <div className="txt_container">
                            <p className='will_fade_in'>BCOAL foundation is the Stellar network, ensuring high-speed, low-cost transactions. The token's value is guaranteed by the equivalent amount of coal held in reserve.</p>
                            <p className='will_fade_in'>This mechanism reinforces stability and transparency, making BCOAL a reliable investment option. BCOAL reserves consist of an impressive 23 million tons of above-surface coal located in Fiat, Illinois.</p>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    )
}
export default Home;